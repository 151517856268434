import React from "react";
import logo from "./assets/imgs/logo.png";
import video from "./assets/video/video.mp4";
import "./App.css";

const iframe = () => {
  return {
    __html:
      '<iframe src="https://show.co/social-unlock/0nC8bImGLtc1ZYyq9D38mL/widget" width="300" height="300" frameborder="0"></iframe>',
  };
};
function App() {
  return (
    <div className="App">
      <div style={{ position: "absolute", width: "100%", height: "100%" }}>
        <video loop autoPlay muted src={video}></video>
        <div
          style={{
            position: "absolute",
            opacity: 0.7,
            width: "100%",
            height: "100%",
            backgroundColor: "black",
          }}
        ></div>
      </div>
      <header className="App-header">
        <div style={{ position: "absolute" }}>
          <img src={logo} alt="Steel God" />
          <p style={{ marginTop: 40 }}>
            The Youth new Single release on: 06/06/2020
          </p>
          <p style={{ marginTop: 0 }}>Available for Pre-Save on Spotify</p>
          <div dangerouslySetInnerHTML={iframe()}></div>
          <div style={{ marginTop: 40 }}>
            <a
              style={{ color: "white" }}
              href="https://www.facebook.com/Steelgod"
            >
              <i class="facebook icon"></i>
            </a>
            <a
              style={{ color: "white" }}
              href="https://www.instagram.com/steelgod_official/"
            >
              <i class="instagram icon"></i>
            </a>
            <a
              style={{ color: "white" }}
              href="https://play.spotify.com/album/6cyAfd3Xi3r9I712O1OWjP"
            >
              <i class="spotify icon"></i>
            </a>
            <a
              style={{ color: "white" }}
              href="https://www.youtube.com/channel/UCuIZsYtYUgYNbnvTvTXlBsQ"
            >
              <i class="youtube icon"></i>
            </a>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
